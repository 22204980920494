import request from '@/utils/request'


// 查询用户上传列表
export function listUpload(query) {
  return request({
    url: '/user/user-uploads/list',
    method: 'get',
    params: query
  })
}

// 查询用户上传分页
export function pageUpload(query) {
  return request({
    url: '/user/user-uploads/page',
    method: 'get',
    params: query
  })
}

// 查询用户上传详细
export function getUpload(data) {
  return request({
    url: '/user/user-uploads/detail',
    method: 'get',
    params: data
  })
}

// 新增用户上传
export function addUpload(data) {
  return request({
    url: '/user/user-uploads/add',
    method: 'post',
    data: data
  })
}

// 修改用户上传
export function updateUpload(data) {
  return request({
    url: '/user/user-uploads/edit',
    method: 'post',
    data: data
  })
}

// 删除用户上传
export function delUpload(data) {
  return request({
    url: '/user/user-uploads/delete',
    method: 'post',
    data: data
  })
}
export function approveUpload(data) {
  return request({
    url: '/user/user-uploads/approve',
    method: 'post',
    data: data
  })
}