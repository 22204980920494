<template>
  <a-modal title='详情' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk' @cancel='close'
           cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form>
        <a-card :bordered="false">
          <detail-list title="片单信息" :col="2">
            <detail-list-item term="上传人">{{ formData.userName }}</detail-list-item>
            <detail-list-item term="剧名">{{ formData.title }}</detail-list-item>
            <detail-list-item term="分类">{{ formData.videoType }}</detail-list-item>
            <detail-list-item term="类型">{{ formData.tags}}</detail-list-item>
            <detail-list-item term="地区">{{ formData.videoCountry }}</detail-list-item>
            <detail-list-item term="时间">{{ formData.videoTime }}</detail-list-item>
            <detail-list-item term="副标题">{{ formData.subtitle }}</detail-list-item>
            <detail-list-item term="审核状态">{{ formData.status | statusFilter}}</detail-list-item>

          </detail-list>
          <a-divider style="margin-bottom: 32px"/>
          <detail-list title="简介" :col="1">
            <detail-list-item>{{ formData.introductions }}</detail-list-item>
          </detail-list>

          <a-divider style="margin-bottom: 32px"/>
          <detail-list title="附件" :col="2">
            <a :href="formData.attachUrl+'?download_name='+formData.downloadTitle" download >
              <a-icon type="download" />下载
            </a>
          </detail-list>


        </a-card>


      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import DetailList from '@/components/crud/DetailList'
const DetailListItem = DetailList.Item;
export default {
  name: 'CrudForm',
  components: {
    ApiSelect,
    DetailList,
    DetailListItem,
  },
  props: {
    statusOptions: {
      type: Array,
      default() {
        return [{ id: '2', name: '审核通过' }, { id: '1', name: '审核驳回' },{ id: '0', name: '待审核' }];
      }
    }
  },
  data() {
    return {
      formData: {
        // id: '',
        // userId: null,
        // status: null,
        // failReason:null
      },           // 表单数据
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交
      isReadOnly: false,
      labelCol: { xs: { span: 24 }, sm: { span: 5 } },    // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, // 表单项内容宽度配置
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: '待审核',
        1: '审核通过',
        2: '审核驳回',
      };
      return statusMap[status]
    },
    typeFilter(status) {
      const statusMap = {
        1: '支付宝',
        2: '微信',
        3: '银行卡'
      };
      return statusMap[status]
    },

  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} record 数据
     */
    audit(record) {
      // this.formData.id = record.id;
      // this.formData.userId = record.userId;
      // this.formData.status = null;
      // this.formData.failReason = '';
      this.formData = record
      this.confirmLoading = false;

      this.visible = true;
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
      this.isReadOnly = false;
    },
    /**
     * 提交
     */
    handleOk() {
      this.close()
    },
  }
}
</script>

<style lang='less' scoped></style>