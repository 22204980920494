<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="剧名" prop="title" >
        <a-input v-model="form.title" placeholder="请输入剧名" />
      </a-form-model-item>
      <a-form-model-item label="视频分类id" prop="videoTypeId" >
        <a-input v-model="form.videoTypeId" placeholder="请输入视频分类id" />
      </a-form-model-item>
      <a-form-model-item label="标签ids" prop="tagIds" >
        <a-input v-model="form.tagIds" placeholder="请输入标签ids" />
      </a-form-model-item>
      <a-form-model-item label="地区" prop="videoCountryId" >
        <a-input v-model="form.videoCountryId" placeholder="请输入地区" />
      </a-form-model-item>
      <a-form-model-item label="视频年份" prop="videoTimeId" >
        <a-input v-model="form.videoTimeId" placeholder="请输入视频年份" />
      </a-form-model-item>
      <a-form-model-item label="副标题" prop="subtitle" >
        <a-input v-model="form.subtitle" placeholder="请输入副标题" />
      </a-form-model-item>
      <a-form-model-item label="简介" prop="introductions" >
        <a-input v-model="form.introductions" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="附件地址" prop="attachUrl" >
        <a-input v-model="form.attachUrl" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="状态0待审核1审核通过2审核驳回" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="审核失败原因" prop="failureReason" >
        <a-input v-model="form.failureReason" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUpload, addUpload, updateUpload } from '@/api/user/upload'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        title: null,

        videoTypeId: null,

        tagIds: null,

        videoCountryId: null,

        videoTimeId: null,

        subtitle: null,

        introductions: null,

        attachUrl: null,

        status: 0,

        failureReason: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '剧名不能为空', trigger: 'blur' }
        ],
        subtitle: [
          { required: true, message: '副标题不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        title: null,
        videoTypeId: null,
        tagIds: null,
        videoCountryId: null,
        videoTimeId: null,
        subtitle: null,
        introductions: null,
        attachUrl: null,
        status: 0,
        failureReason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUpload({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUpload(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUpload(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
